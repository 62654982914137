<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="rest"
        :readonly="!canEdit"
        required
        @change="handleChange('scriptEngine', $event)"
      />
    </v-col>
    <v-col cols="12" class="pt-1">
      <v-text-field
        :key="`${step.id}-query`"
        v-model="properties.query"
        outlined
        dense
        :label="$lang.labels.query"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ScriptEnginesSelect } from './components'
import StepMixin from './StepMixin'

export default {
  name: 'WhileStep',
  components: {
    ScriptEnginesSelect
  },
  mixins: [StepMixin]
}
</script>
